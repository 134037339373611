const staySignedIn = JSON.parse(localStorage.getItem("keep_signed_in"));
const initialState = staySignedIn ? { keep_signed_in: true } : { keep_signed_in: false };

export const keepSignedIn = {
  namespaced: true,
  state: initialState,
  actions: {
    setKeepSignedIn({ commit }, status) {
      commit(status ? "keepSign" : "keepSignFailed");
      localStorage.setItem("keep_signed_in", status);
    },
  },
  mutations: {
    keepSign(state) {
      state.keep_signed_in = true;
    },
    keepSignFailed(state) {
      state.keep_signed_in = false;
    },
    keepSignOut(state) {
      state.keep_signed_in = false;
    }
  },
};
