<template>
  <router-view />
</template>

<script>
export default {
  name: "App"
};
</script>

<style>
nav a.router-link-exact-active {
  color: #42b983;
}
</style>
