export const toggleNavigation = {
    namespaced: true,
    state: {
        isNavigationOpen: false
    },
    mutations: {
        toggleNavigation(state) {
            state.isNavigationOpen = !state.isNavigationOpen;
        },
        closeNavigation(state) {
            state.isNavigationOpen = false;
          }
    },
    actions: {
        toggleNavigation({ commit }) {
            commit('toggleNavigation'); // Call the mutation with the correct name
        }
    },
    getters: {
        isNavigationOpen: state => state.isNavigationOpen
    }
};

